import React from "react";
import styles from "./Dates.module.css";
import moment from "moment";

export const Fecha = ({ date, event, appointmentType }) => (
  <div
    className={styles.Date}
    onClick={(e) => selectDate(e, event, date, appointmentType)}
  >
    {moment(date).format("hh:mm a")}
  </div>
);

const selectDate = (e, event, time, appointmentType) => {
  const dates = Array.from(document.getElementsByClassName(styles.Date));
  dates.forEach((date) => date.classList.remove(styles.DateSelected));
  e.target.classList.add(styles.DateSelected);
  event({ time, appointmentType });
};
