import React from "react";
import { Home } from "./Components/Home/Home";
import { Shell } from "./Components/Shell/Shell";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { getCountry } from "./util/Utils";
import { getUTMs } from "./util/UTM";
import Test from "./Components/Test/Test";
import { ViewCalendar } from "./Components/ViewCalendar/ViewCalendar";
import { Fin } from "./Components/Fin/Fin";
import { FinishAppointment } from "./Components/FinishAppoinment/FinishAppointment";
import NoCandidate from "./Components/NoCandidate/NoCandidate";
import { useTracking } from "./util/tracking";

export const Views = () => {
  useTracking("UA-139614898-5");
  const country = getCountry();
  return (
    <Switch>
      <Route exact path="/" component={Home} />
      <Shell>
        <Route
          exact
          path="/quizkit"
          render={(props) => <Test {...props} country={country} />}
        />
        <Route exact path="/agendarkit" component={Fin} />
        <Route exact path="/nocandidatokit" component={NoCandidate} />
        <Route exact path="/calendariokit" component={ViewCalendar} />
        <Route exact path="/graciaskit" component={FinishAppointment} />
      </Shell>
    </Switch>
  );
};

function App() {
  getUTMs();
  return (
    <BrowserRouter>
      <Views />
    </BrowserRouter>
  );
}

export default App;
