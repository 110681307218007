import React, {useEffect, useState} from 'react';
import GenericButton from '../GenericButton/GenericButton';

export const Home = (props) => {
    const [isLogged, setIsLogged] = useState(false)
    useEffect(() => {
        const isLogin = window.localStorage.getItem('login')
        if (isLogin) {
            setIsLogged(true)
            window.localStorage.setItem('login', 'true')
        }
    }, [setIsLogged])

    const setIn = () => {
        window.localStorage.setItem('login', 'true')
    }
    return (<div>
            {isLogged ? <GenericButton
                to="/"
                label="LOGGED"
                event={() => alert('Redirect')}
            /> : <GenericButton
                label="LOGIN"
                to="/"
                event={() => setIn()}
            />}
        </div>
    );
};
