import React, { Component } from "react";
import styles from "./Question5-6.module.css";
import check from "../../assets/images/svg/check.svg";
import { CONDITIONS, REAZONS } from "../../util/Constants";

export default class Question56 extends Component {
  constructor() {
    super();
    this.state = {
      enable: false,
    };
    window.scrollTo(0, 0);
  }

  selectHandler = (e) => {
    const id = e.target.id + "-check";
    if (this.props.question === 5) {
      this.clearSelect(id);
    } else {
      if (id.includes("6")) {
        this.clearSelect(id);
      } else {
        document.getElementById(id).classList.toggle(styles.hide);
        document.getElementById(e.target.id).classList.toggle(styles.border);
        document
          .getElementById(e.target.id)
          .classList.toggle(styles.borderSelected);
        document.getElementById("6-check").classList.add(styles.hide);
        document.getElementById("6").classList.add(styles.border);
        document.getElementById("6").classList.remove(styles.borderSelected);
      }
    }
    this.setState({ enable: true });
  };

  clearSelect = (id) => {
    const icons = document.getElementsByClassName(styles.leftIcon);
    Array.from(icons).forEach((i, index) => {
      i.classList.add(styles.hide);
      document.getElementById(index).classList.add(styles.border);
      document.getElementById(index).classList.remove(styles.borderSelected);
    });
    document.getElementById(id).classList.remove(styles.hide);
    document.getElementById(id.charAt(0)).classList.add(styles.borderSelected);
  };

  conditions = () => {
    const options = this.props.question === 4 ? CONDITIONS : REAZONS;
    return options.map((con) => {
      return (
        <div
          id={con.code}
          key={con.code}
          className={styles.border}
          onClick={this.selectHandler}
        >
          <div
            id={`${con.code}-check`}
            className={`${styles.leftIcon} ${styles.hide}`}
          >
            <img src={check} size={12} color={"white"} alt="check moons" />
          </div>
          <div id={con.code} className={styles.contentSize}>
            {con.desc}
          </div>
        </div>
      );
    });
  };

  nextHandler = () => {
    const options = document.getElementsByClassName(styles.leftIcon);
    const selected = Array.from(options).map(
      (op) => !op.className.includes(styles.hide)
    );
    this.props.question === 4
      ? this.props.question4Handler(selected)
      : this.props.question5Handler(selected);
  };

  render() {
    const name = this.props.name.split(" ");
    return (
      <div className={`${styles.containerFlex} ${styles.heightContainer}`}>
        <div className={styles.textCenter}>
          {this.props.question === 4 && (
            <>
              <p className={styles.title}>
                ¿Tienes alguna de estas condiciones?
              </p>
              <p className={styles.subTitle}>Elige todas las que apliquen.</p>
            </>
          )}
          {this.props.question === 5 && (
            <>
              <p className={styles.title}>
                {name[0]}, ¿tienes un motivo especial para mejorar tu sonrisa?
              </p>
            </>
          )}

          <div className={styles.options}>{this.conditions()}</div>
        </div>
        <div className={styles.nextContainer}>
          {this.state.enable && (
            <div
              id="next"
              className={styles.buttonNext}
              onClick={this.nextHandler}
            >
              SIGUIENTE{" "}
            </div>
          )}
          <br />
          <br />
          <br />
        </div>
      </div>
    );
  }
}
