import React, { Component } from "react";
import styles from "./Question1.module.css";

export default class Question1 extends Component {
  constructor() {
    super();
    this.state = {
      enable: true,
      cpError: undefined,
    };
  }

  nextHandler = () => {
    const name = document.getElementById("name").value;
    const nameArray = name.split(" ");
    if (nameArray.length > 1) {
      window.dataLayer.push({ event: "Start-Quiz" });
      this.props.question1Handler(name);
    } else {
      this.setState({ cpError: true });
    }
  };

  componentDidMount() {
    const img = document.createElement("img");
    img.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=PageView&noscript=1"
    );
    document.getElementById("inputStart").appendChild(img);
  }

  render() {
    window.scrollTo(0, 0);
    return (
      <div className={`${styles.containerFlex} ${styles.heightContainer}`}>
        <p className={styles.mainTitle}>
          Responde este test y descubre si los moons son para ti
        </p>
        <div>
          <p className={`${styles.title} ${styles.titleContainer}`}>
            Primero, ¿cómo te llamas?
          </p>
          <div className={styles.buttonsContainer} id="inputStart">
            <div className={styles.inputContainer}>
              <div className={styles.form}>
                <label htmlFor="name" className={styles.inp}>
                  <input type="text" id="name" placeholder="&nbsp;" />
                  <span className={styles.label}>Tu nombre y apellido</span>
                  <span className={styles.border}></span>
                </label>
              </div>
              {this.state.cpError && (
                <label className={styles.error}>
                  Ingresa un nombre y al menos un apellido
                </label>
              )}
            </div>
          </div>
        </div>
        <div className={styles.nextContainer}>
          {this.state.enable && (
            <button
              id="next"
              className={styles.buttonNext}
              onClick={this.nextHandler}
            >
              SIGUIENTE{" "}
            </button>
          )}
        </div>
      </div>
    );
  }
}
