const set1stDataLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify([value]));
};

const isNull = (data) => data === null;

const getDataFromLocalStorage = (key) => {
  const data = JSON.parse(window.localStorage.getItem(key));
  return isNull(data) ? [] : data;
};

const set1stOrganicLocalStorage = (key) => {
  window.localStorage.setItem(key, JSON.stringify(["organico"]));
};

const setDataLocalStorage = (key, value) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

const proccessDataLocalStorage = (name, data) => {
  if (isNull(data)) {
    const dataFromLocalStorage = getDataFromLocalStorage(name);
    if (dataFromLocalStorage.length > 0) {
      dataFromLocalStorage.push("organico");
      setDataLocalStorage(name, dataFromLocalStorage);
    } else {
      set1stOrganicLocalStorage(name);
    }
  } else {
    const dataFromLocalStorage = getDataFromLocalStorage(name);
    if (dataFromLocalStorage.length < 15) {
      dataFromLocalStorage.push(data);
      setDataLocalStorage(name, dataFromLocalStorage);
    } else {
      set1stDataLocalStorage(name, data);
    }
  }
};

export const getUTMs = () => {
  const url = new URL(window.location.href);
  const utm_source = url.searchParams.get("utm_source");
  const utm_campaign = url.searchParams.get("utm_campaign");
  const utm_medium = url.searchParams.get("utm_medium");
  const utm_content = url.searchParams.get("utm_content");
  const creative = url.searchParams.get("creative");
  const appointment = url.searchParams.get("appointment_source");
  const payment = url.searchParams.get("payment_source");
  saveUtm_info(
    utm_source,
    utm_campaign,
    utm_medium,
    creative,
    payment,
    appointment,
    utm_content
  );
};

const saveUtm_info = (
  source,
  campain,
  medium,
  creative,
  payment,
  appointment,
  content
) => {
  proccessDataLocalStorage("utm_source", source);
  proccessDataLocalStorage("utm_campaign", campain);
  proccessDataLocalStorage("utm_medium", medium);
  proccessDataLocalStorage("creative", creative);
  proccessDataLocalStorage("payment_source", payment);
  proccessDataLocalStorage("appointment_source", appointment);
  proccessDataLocalStorage("utm_content", content);
};
