import {
  ACUITY_API_GATEWAY,
  APPOINTMENTS_DISPONIBILITY_URL,
} from "../util/Constants";

const callApi = async (endpoint, options) => {
  const response = await fetch(endpoint, options);
  return await response.json();
};

export const apiAcuity = {
  getAppointments(day) {
    return callApi(APPOINTMENTS_DISPONIBILITY_URL + day, {
      headers: {
        "Content-Type": "application/json",
        Authorization:
          "Basic " + Buffer.from("admin:qwpozxmn09").toString("base64"),
      },
      method: "GET",
    });
  },
  createAppointment(datetime, appointmentTypeId, name, email, phone) {
    return callApi(ACUITY_API_GATEWAY + "appointments", {
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        datetime: datetime,
        appointmentTypeID: appointmentTypeId,
        firstName: name,
        lastName: "-",
        email: email,
        phone: phone,
      }),
    });
  },
};
