import React, { Component } from "react";
import styles from "./Question7.module.css";
import Form from "../Form/Form";

export default class Question7 extends Component {
  handleForm = (data) => {
    const pixel = document.createElement("img");
    pixel.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=Lead&noscript=1"
    );
    document.getElementById("header").appendChild(pixel);
    window.dataLayer.push({ event: "Finish-Quiz" });
    this.props.formHandle(data);
  };

  render() {
    window.scrollTo(0, 0);
    return (
      <div className={`${styles.containerFlex} ${styles.heightContainer}`}>
        <div>
          <p className={styles.title}>¿A dónde enviamos tus resultados?</p>
          <Form formHandle={this.handleForm} />
        </div>
      </div>
    );
  }
}
