import React, { Component } from "react";
import styles from "./NoCandidate.module.css";
import SocialMedia from "../SocialMedia/SocialMedia";

export default class NoCandidate extends Component {
  handleForm = (data) => this.props.formHandle(data);

  render() {
    const message = this.props.location.state.message;
    window.dataLayer.push({ event: "No-Candidate" });
    return (
      <div className={styles.containerFlex}>
        <div className={styles.background} />

        <div className={styles.content}>
          <div className={styles.title}>Oh, no…</div>

          <div className={`${styles.subTitle} ${styles.noCandidateSubTitle}`}>
            {message === 0 && (
              <p className={styles.textCenter}>
                Por ahora los moons son sólo <br />
                <b>para mayores de edad.</b>
              </p>
            )}
            {message > 0 && (
              <p className={styles.textCenter}>
                Debido a tu condición,{" "}
                <b>no te recomendamos usar ningún tipo de ortodoncia</b> sin
                consultar a tu médico.
                <br />
                ¡Tu salud es primero!
              </p>
            )}

            <SocialMedia country={this.props.country} />
          </div>
        </div>
      </div>
    );
  }
}
