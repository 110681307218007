import React, { Component } from "react";
import styles from "./Calendar.module.css";
import moment from "moment";
import "moment/locale/es";
import { Fecha } from "./Dates";
import { LoaderSection } from "../LoaderSection/LoaderSection";
import { apiAcuity } from "../../Apis/Acuity";
import next from "../../assets/images/svg/next.svg";
import prev from "../../assets/images/svg/previous.svg";

export class Calendar extends Component {
  constructor() {
    super();
    const today = moment();

    this.state = {
      date: today,
      avalibleDays: [],
      avalibleTimes: [],
      dateSelected: 0,
      loader: true,
      loaderPage: false,
      showButton: false,
    };
  }

  componentDidMount() {
    this.getAvalibleDates(this.state.date);
  }

  getAvalibleDates = async (date) => {
    const avaliblesDays = [];
    let dateAux = moment(date);
    const daysFutures = 6;
    const timeLimit = moment("6:30pm", "h:mma");
    if (dateAux.isAfter(timeLimit)) {
      dateAux = moment(dateAux).add(1, "days");
    }
    while (avaliblesDays.length < daysFutures) {
      /* if (dateAux.isoWeekday() < 6) { */
      avaliblesDays.push(dateAux);
      dateAux = moment(dateAux).add(1, "days");
      /* } else {
        dateAux = moment().add(1, "weeks").isoWeekday(1);
      } */
    }

    const avalibleTimes = await this.getAvalibleTimes(
      avaliblesDays[this.state.dateSelected]
    );

    this.setState({
      avalibleDays: avaliblesDays,
      avalibleTimes: avalibleTimes,
      loader: false,
    });
  };

  getAvalibleTimes = async (date) =>
    await apiAcuity.getAppointments(
      moment(date).startOf("day").format("YYYY-MM-DDTHH:mm:ssZZ")
    );

  updateAcuityData = async (newSelected) => {
    this.setState({
      dateSelected: newSelected,
      loader: true,
      showButton: false,
    });
    const avaliblesT = await this.getAvalibleTimes(
      this.state.avalibleDays[newSelected]
    );
    this.setState({ avalibleTimes: avaliblesT, loader: false });
  };

  nextDay = async () => {
    const { dateSelected, avalibleDays } = this.state;
    if (dateSelected < avalibleDays.length - 1)
      this.updateAcuityData(dateSelected + 1);
  };

  prevDay = async () => {
    const { dateSelected } = this.state;
    if (dateSelected > 0) this.updateAcuityData(dateSelected - 1);
  };

  showButton = (date) =>
    this.setState({
      showButton: true,
      dateTime: date.time,
      appointmentType: date.appointmentType,
    });

  handleAgendar = async () => {
    const pixel = document.createElement("img");
    pixel.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=Schedule&noscript=1"
    );
    document.getElementById("header").appendChild(pixel);
    const { name, phone, email, link } = this.props;
    this.setState({ loaderPage: true });
    const response = await apiAcuity.createAppointment(
      this.state.dateTime,
      this.state.appointmentType,
      name,
      email,
      phone
    );
    this.props.event({ time: response.datetime, link });
  };

  render() {
    moment.locale("es");
    const { avalibleDays, dateSelected, loaderPage } = this.state;

    if (!avalibleDays[dateSelected] || loaderPage) {
      return (
        <div className={styles.LoaderContainer}>
          <LoaderSection />
        </div>
      );
    }

    const title = avalibleDays[dateSelected].isSame(moment(this.state.date))
      ? "Hoy"
      : "";
    const titleDay = moment(avalibleDays[dateSelected]).format(
      "DD [de] MMMM [del] YYYY"
    );

    return (
      <div className={styles.Container}>
        <div className={styles.HeaderContent}>
          <div className={styles.HeaderTitle}>{this.props.name}</div>
          <div className={styles.HeaderTitle}>¿Cuándo quieres tu cita?</div>
        </div>
        {!Array.isArray(this.state.avalibleTimes) && (
          <p>No hay citas disponibles</p>
        )}

        {Array.isArray(this.state.avalibleTimes) && (
          <div className={styles.Container}>
            <div className={styles.Header}>
              <div className={styles.Arrow} onClick={this.prevDay}>
                <img src={prev} alt="previous day" height="10" />
              </div>
              <div className={styles.TitleContainer}>
                <div className={styles.Title}>{title}</div>
                <div className={styles.Title}>{titleDay}</div>
              </div>
              <div className={styles.Arrow} onClick={this.nextDay}>
                <img src={next} alt="next day" height="10" />
              </div>
            </div>
            {this.state.loader && (
              <div className={styles.LoaderSection}>
                <LoaderSection />
              </div>
            )}
            {!this.state.loader && (
              <div className={styles.DatesContainer}>
                {this.state.avalibleTimes.length === 0 && (
                  <p>No hay citas para este dia</p>
                )}
                {this.state.avalibleTimes.map((time) => (
                  <Fecha
                    date={time.time}
                    key={time.time}
                    event={this.showButton}
                    appointmentType={time.attendant.appointmentTypeId}
                  />
                ))}
              </div>
            )}
            {this.state.showButton && (
              <div
                className={styles.ButtonContainer}
                onClick={this.handleAgendar}
              >
                AGENDAR
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
