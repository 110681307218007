import React from "react";
import styles from "./Fin.module.css";
import { NavLink } from "react-router-dom";
import { apiBlackBox } from "../../Apis/BlackBox";
import { v4 as uuidv4 } from "uuid";
import { VIDEOLLAMADA_URL } from "../../util/Constants";
import { getUtcs } from "../../util/Utils";
//import imgHotSale from "../../assets/images/png/logohotsale.png";

export const Fin = (props) => {
  const { name, phone, email } = props.location.state;
  const link = VIDEOLLAMADA_URL + uuidv4();
  const firstName = name.split(" ")[0];
  const imgZyman = window.webpSupported
    ? require("../../assets/images/webp/zyman.webp")
    : require("../../assets/images/png/zyman.png");

  const handlePixel = () => {
    window.dataLayer.push({ event: "sendWA" });
    const pixel = document.createElement("img");
    pixel.setAttribute(
      "src",
      "https://www.facebook.com/tr?id=427318704548057&ev=Contact&noscript=1"
    );
    document.getElementById("header").appendChild(pixel);
  };

  const saveData = async () => {
    const utcs = getUtcs();
    await apiBlackBox.Patient(
      phone,
      email,
      name,
      utcs,
      "TestVideollamada3",
      "México",
      link
    );
  };

  saveData();

  return (
    <div className={styles.Content}>
      {/* <img src={imgHotSale} className={styles.ImageHS} alt="HotSale" /> */}
      <div className={styles.ImageContainer}>
        <img src={imgZyman} className={styles.Image} alt="Zyman moons" />
      </div>
      <div className={styles.InfoContainer}>
        <div className={styles.Title}>¡Felicidades, {firstName}!</div>
        <div className={styles.SubTitle}>Parece que si eres candidato</div>
        <div className={styles.Text}>
          Ahora agenda una videollamada con uno de nuestros ortodoncistas para
          comenzar con tu asesoría dental totalmente gratis
        </div>
        <div className={styles.ButtonContainer}>
          <NavLink
            to={{
              pathname: "/calendariokit",
              state: { phone, email, name, link },
            }}
          >
            <button className={styles.Button} onClick={() => handlePixel()}>
              AGENDAR VIDEOLLAMADA
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
};
