import React from "react";
import styles from "./FinishAppointment.module.css";
import moment from "moment";

export const FinishAppointment = (props) => {
  moment.locale("es");

  const astro = window.webpSupported
    ? require("../../assets/images/webp/astro.webp")
    : require("../../assets/images/png/astro.png");

  const { name, link, time } = props.location.state;
  const text = "Videollamada moons";
  const timeStart = moment(time).format("YYYYMMDDTHHmmss");
  const timeEnd = moment(time).add(30, "minutes").format("YYYYMMDDTHHmmss");

  const calendar = `https://calendar.google.com/calendar/r/eventedit?dates=${timeStart}/${timeEnd}&text=${text}&details=${link}&sf=true`;

  return (
    <div className={styles.Container}>
      <div className={styles.ImageContainer}>
        <img className={styles.Image} src={astro} alt="Astronauta Moons" />
      </div>
      <div className={styles.InfoContainer}>
        <div className={styles.Title}>
          ¡Nos vemos pronto, {name.split(" ")[0]}!
        </div>
        <div className={styles.Text}>
          Estamos felices de ayudarte a tener una sonrisa que te haga feliz.
        </div>
        <div className={styles.TitleAppoinment}>Tu cita es el día:</div>
        <div className={styles.TitleAppoinment}>
          {moment(time).format("dddd DD [de] MMMM, HH:mm [hrs.]")}
        </div>
        <div className={styles.SubTitle}>Ingresa a este link para tu cita:</div>
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.LinkContainer}
        >
          {link}
        </a>
        <a
          href={calendar}
          className={styles.ButtonContainer}
          rel="noopener noreferrer"
          target="_blank"
        >
          AGREGAR A CALENDARIO
        </a>
      </div>
    </div>
  );
};
