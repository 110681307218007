import React, { Component } from "react";
import check from "../../assets/images/svg/check.svg";
import styles from "./Question2.module.css";
import dientesSeparados from "../../assets/images/svg/dientes_separados.svg";
import dientesEncimados from "../../assets/images/svg/dientes_encimados.svg";
import mordida from "../../assets/images/svg/mordida.svg";
import enderezar from "../../assets/images/svg/enderezar.svg";

export default class Question2 extends Component {
  constructor() {
    super();
    this.state = {
      encimados: false,
      separados: false,
      enderezar: false,
      mordida: false,
      enable: false
    };
    window.scrollTo(0, 0);
  }

  imagesHandler = async id => {
    if (window.innerWidth <= 320) {
      window.scrollTo(0, window.innerHeight);
    } else if (window.innerHeight < 650) {
      window.scrollTo(0, window.innerHeight / 3);
    }
    document.getElementById(id).classList.toggle(styles.hide);
    document.getElementById(`${id}-container`).classList.toggle(styles.boder);
    document
      .getElementById(`${id}-container`)
      .classList.toggle(styles.borderSelected);

    await this.setState({ [id]: !this.state[id] });

    if (
      !this.state.mordida &&
      !this.state.encimados &&
      !this.state.separados &&
      !this.state.enderezar
    ) {
      this.setState({ enable: false });
    } else {
      this.setState({ enable: true });
    }
  };

  nextHandler = () => {
    const resp = {
      encimados: this.state.encimados,
      separados: this.state.separados,
      enderezar: this.state.enderezar,
      mordida: this.state.mordida
    };
    this.props.question2Handler(resp);
  };

  render() {
    const name = this.props.name.split(" ");
    return (
      <div className={`${styles.containerFlex} ${styles.heightContainer}`}>
        <div className={styles.textCenter}>
          <p className={styles.title}>
            ¡Hola, {name[0]}! Soy Apolo, tu guía moons 👋🏽
            <br />
            Cuéntame, ¿qué te gustaría cambiar de tu sonrisa?
          </p>
          <p className={styles.subTitle}>Elige todas las que apliquen.</p>
          <div className={styles.imagesContaier}>
            <button
              onClick={() => this.imagesHandler("encimados")}
              className={styles.boder}
              id="encimados-container"
            >
              <div
                id="encimados"
                className={`${styles.topIcon} ${styles.hide}`}
              >
                <img
                  src={check}
                  style={{ paddingTop: 5 }}
                  color={"white"}
                  alt="check moons"
                />
              </div>
              <div className={styles.imgContainer}>
                <img
                  className={styles.imgDientes}
                  src={dientesEncimados}
                  height="103"
                  width="155"
                  alt="Dientes encimados"
                />
                Dientes encimados
              </div>
            </button>
            <button
              onClick={() => this.imagesHandler("separados")}
              className={styles.boder}
              id="separados-container"
            >
              <div
                id="separados"
                className={`${styles.topIcon} ${styles.hide}`}
              >
                <img
                  src={check}
                  size={12}
                  style={{ paddingTop: 5 }}
                  color={"white"}
                  alt="check moons"
                />
              </div>
              <div className={styles.imgContainer}>
                <img
                  className={styles.imgDientes}
                  src={dientesSeparados}
                  alt="Dientes separados"
                  height="103"
                  width="155"
                />
                Dientes separados
              </div>
            </button>
            <button
              onClick={() => this.imagesHandler("enderezar")}
              className={styles.boder}
              id="enderezar-container"
            >
              <div
                id="enderezar"
                className={`${styles.topIcon} ${styles.hide}`}
              >
                <img
                  src={check}
                  size={12}
                  style={{ paddingTop: 5 }}
                  color={"white"}
                  alt="check moons"
                />
              </div>
              <div className={styles.imgContainer}>
                <img
                  className={styles.imgDientes}
                  src={enderezar}
                  alt="Enderezar dientes"
                  height="103"
                  width="155"
                />
                Enderezarlos
              </div>
            </button>
            <button
              onClick={() => this.imagesHandler("mordida")}
              className={styles.boder}
              id="mordida-container"
            >
              <div id="mordida" className={`${styles.topIcon} ${styles.hide}`}>
                <img
                  src={check}
                  size={12}
                  style={{ paddingTop: 5 }}
                  color={"white"}
                  alt="check moons"
                />
              </div>
              <div className={styles.imgContainer}>
                <img
                  className={styles.imgDientes}
                  src={mordida}
                  alt="La mordida"
                  height="103"
                  width="155"
                />
                La mordida
              </div>
            </button>
          </div>
        </div>
        <div className={styles.nextContainer}>
          {this.state.enable && (
            <div
              id="next"
              className={styles.buttonNext}
              onClick={this.nextHandler}
            >
              SIGUIENTE{" "}
            </div>
          )}
        </div>
      </div>
    );
  }
}
