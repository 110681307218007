import React, { Component } from "react";
import Question1 from "../Question1/Question1";
import Question2 from "../Question2/Question2";
import Question3 from "../Question3/Question3";
import Question4 from "../Question4/Question4";
import Question56 from "../Question5-6/Question5-6";
import Question7 from "../Question7/Question7";
import LoaderFullScreen from "../LoaderFullScreen/LoaderFullScreen";

export default class Test extends Component {
  constructor() {
    super();
    this.state = {
      currentStep: 0,
      answers: [
        /* "Edwin Gonzalez",
        { encimados: false, enderezar: false, mordida: false, separados: true },
        true,
        true,
        [true, false, false, false, false, false, false],
        [false, false, false, true],
        { email: "edwin.gonzalez@mymoons.mx", phone: "5535161179" },
        "Mexico", */
      ],
      noCandidate: false,
      noCadidateStep: 0,
      message: undefined,
    };
  }

  componentDidMount() {
    const url = new URL(window.location.href);
    let utm_source = url.searchParams.get("utm_source");
    let utm_campaign = url.searchParams.get("utm_campaign");
    let utm_medium = url.searchParams.get("utm_medium");
    let appointment_source = url.searchParams.get("appointment_source");
    let creative = url.searchParams.get("creative");
    utm_source = utm_source === null ? "Organico" : utm_source;
    utm_campaign = utm_campaign === null ? "Organico" : utm_campaign;
    utm_medium = utm_medium === null ? "Organico" : utm_medium;
    appointment_source =
      appointment_source === null ? "Organico" : appointment_source;
    creative = creative === null ? "Organico" : creative;
    window.localStorage.setItem("utm_source", JSON.stringify([utm_source]));
    window.localStorage.setItem("utm_campaign", JSON.stringify([utm_campaign]));
    window.localStorage.setItem("utm_medium", JSON.stringify([utm_medium]));
    window.localStorage.setItem("creative", JSON.stringify([creative]));
    window.localStorage.setItem(
      "appointment_source",
      JSON.stringify([appointment_source])
    );
  }

  startHandle = () => {
    const currentStep = this.state.currentStep + 1;
    this.setState({ currentStep });
  };

  saveInfo = (res) => {
    const currentStep = this.state.currentStep + 1;
    const answers = this.state.answers;
    answers.push(res);
    this.setState({ currentStep, answers });
  };

  question3Handler = (res) => {
    if (res) {
      this.saveInfo(res);
    } else {
      const answers = this.state.answers;
      answers.push(res);
      this.setState({ noCandidate: true, message: 0 });
    }
  };

  question = (res) => this.saveInfo(res);

  question4Handler = (res) => {
    if (res[5]) this.setState({ noCandidate: true, message: 3 });
    this.saveInfo(res);
  };

  handleFullLoader = () =>
    this.props.history.push("/agendarkit", {
      country: this.props.country,
      name: this.state.answers[0],
      phone: this.state.answers[6].phone,
      email: this.state.answers[6].email,
    });

  render() {
    if (this.state.noCandidate) {
      this.props.history.push("/nocandidatokit", {
        message: this.state.message,
        country: this.props.country,
      });
    }

    /* const product =
      this.props.country === "Colombia" ? "moons-kit-co" : "moons-kit-mx"; */

    return (
      <>
        {this.state.currentStep === 0 && (
          <Question1
            question1Handler={this.question}
            country={this.props.country}
          />
        )}
        {this.state.currentStep === 1 && (
          <Question2
            question2Handler={this.question}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 2 && (
          <Question3 question3Handler={this.question3Handler} />
        )}
        {this.state.currentStep === 3 && (
          <Question4
            question4Handler={this.question}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 4 && (
          <Question56
            question={4}
            question4Handler={this.question4Handler}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 5 && (
          <Question56
            question={5}
            question5Handler={this.question}
            name={this.state.answers[0]}
          />
        )}
        {this.state.currentStep === 6 && (
          <Question7 formHandle={this.question} country={this.props.country} />
        )}
        {this.state.currentStep === 7 && (
          <LoaderFullScreen
            timer={this.handleFullLoader}
            message={"Estamos revisando tus respuestas…"}
          />
        )}
      </>
    );
  }
}
