import React from "react";
import { Calendar } from "../Calendar/Calendar";

export const ViewCalendar = (props) => {
  const { name, phone, email, link } = props.location.state;

  const handleSuccess = (data) =>
    props.history.push("graciaskit", {
      link: data.link,
      time: data.time,
      name,
    });

  return (
    <Calendar
      name={name}
      phone={phone}
      email={email}
      link={link}
      event={handleSuccess}
    />
  );
};
