import React, { Component } from "react";
import moon from "../../assets/images/png/lunafooter.png";
import ring from "../../assets/images/png/path.png";
import styles from "./LoaderFullScreen.module.css";

export default class LoaderFullScreen extends Component {
  componentDidMount() {
    setTimeout(() => this.props.timer(), 3000);
  }

  render() {
    window.scrollTo(0, 0);
    return (
      <div className={styles.Container}>
        <div className={styles.SealRing}>
          <img src={ring} alt="Animacion" />
          <div className={styles.SealMoon}>
            <img src={moon} alt="Holograma Moons" />
          </div>
        </div>
        <div className={styles.ContainerMessage}>{this.props.message}</div>
      </div>
    );
  }
}
