import React, { Component } from "react";
import styles from "./Question4.module.css";

export default class Question4 extends Component {
  constructor() {
    super();
    this.state = {
      enable: false
    };
  }

  handleYesNoButton = e => {
    const id = e.target.id;
    document.getElementById(id).classList.add(styles.fillButtom);
    if (id === "yes") {
      document.getElementById("no").classList.remove(styles.fillButtom);
    } else {
      document.getElementById("yes").classList.remove(styles.fillButtom);
    }
    const res = id === "yes";
    this.setState({ enable: true, res });
  };

  nextHandler = () => {
    this.props.question4Handler(this.state.res);
  };

  render() {
    window.scrollTo(0, 0);
    const name = this.props.name.split(" ");
    return (
      <div className={`${styles.containerFlex} ${styles.heightContainer}`}>
        <div>
          <p className={`${styles.title} ${styles.titleContainer}`}>
            ¿Has usado brackets u otro tipo de ortodoncia, {name[0]}?
          </p>
          <div className={styles.buttonsContainer}>
            <div className={styles.container}>
              <div>
                <button
                  id="yes"
                  className={`${styles.yesNoButtons} ${styles.buttonSize}`}
                  onClick={this.handleYesNoButton}
                >
                  Sí
                </button>
              </div>
              <div>
                <button
                  id="no"
                  className={`${styles.yesNoButtons} ${styles.buttonSize}`}
                  onClick={this.handleYesNoButton}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.nextContainer}>
          {this.state.enable && (
            <div
              id="next"
              className={styles.buttonNext}
              onClick={this.nextHandler}
            >
              SIGUIENTE{" "}
            </div>
          )}
        </div>
      </div>
    );
  }
}
