import { BLACKBOX_URL } from "../util/Constants";

const callApi = async (options) => {
  const response = await fetch(BLACKBOX_URL, options);
  return await response.json();
};

export const apiBlackBox = {
  Patient(phone, email, name, utcs, origin, country, link) {
    return callApi({
      headers: {
        "Content-Type": "application/json",
      },
      method: "POST",
      body: JSON.stringify({
        email: email,
        phone: phone,
        deal: {
          Patient_Name: name,
          Source: utcs.utm_source,
          Campaign: utcs.utm_campaign,
          Ad_Group: utcs.utm_medium,
          Creative_Name: utcs.creative,
          Deal_Content: utcs.content,
          Deal_Origin: origin,
          Country_Ops: country,
          Videocall_Link: link,
          Sirena_Group: "Precita",
        },
      }),
    });
  },
};
